const isLocalhost = (hostname = '') =>
  Boolean(
    hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
      // 192.0.0.0/8 is considered localhost for IPv4.
      hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
export const resolvePACORemote = (host = window.location.hostname) => {
  // is local
  if (isLocalhost(host)) return process.env.MF_REMOTE || '/';
  // each stage paco agent
  if (/blinxpaco\-np/gi.test(host)) return '/';
  //  productional paco, idp based
  if (/.*?blinxpaco.com/.test(host)) return '/';
  // patient stage side
  // patient-chat.dev.blinxhealthcare.co.uk
  // patient-chat.uat.blinxhealthcare.co.uk
  // patiant-chat.pp.blinxhealthcare.co.uk
  if (/(patient|patiant)-chat\.(dev|uat|pp)\.blinxhealthcare\.co\.uk/gi.test(host)) {
    const stage = host.match(/(dev|uat|pp)/gi)?.[0];
    return `//blinx.${stage}.blinxpaco-np.com/`;
  }
  // production patient
  if (host === 'patient-chat.blinxhealth.com') return '//blinx.blinxpaco.com/';
  throw new Error(`cannot resolve host: ${window.location.host}`);
};
